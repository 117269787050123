import { configureStore } from '@reduxjs/toolkit';
import filters from '../components/core/Filter/redux/filterSlice';
import toolbar from 'components/layout/Toolbar/redux/toolbarSlice';
import auth from 'modules/auth/redux/authSlice';
import timelineSlice from 'modules/timeline/redux/timelineSlice';
import usersSlice from '../modules/people/redux/peopleSlice';
import userSlice from 'modules/profile/redux/userSlice';
import reportTemplatesSlice from 'modules/reports/redux/reportTemplateSlice';
import workflowTemplatesSlice from 'modules/workflows/redux/workflowTemplatesSlice';
import projects from 'modules/projects/redux/projectSlice';
import settingsSlice from 'modules/settings/redux/settingsSlice';
import colorLegend from 'components/core/ColorLegend/redux/colorLegendSlice';
import workflowInstanceSlice from 'modules/workflows/redux/workflowInstanceSlice';
import peoplePopup from 'components/core/Popups/redux/peoplePopupSlice';
import timeAways from 'modules/profile/redux/timeAwaysSlice';
import organizationChart from 'modules/organizationChart/redux/chartSlice';
import dashboardCharts from 'modules/dashboard/redux/dashboardSlice';
import tasksSlice from 'modules/tasks/redux/tasksSlice';
import ptoPolicySlice from 'modules/shared/redux/ptoPolicy/ptoPolicySlice';
import calendarSlice from 'modules/shared/redux/calendar/calendarSlice';
import timesheetsSlice from 'modules/timesheet/redux/timesheetsSlice';

import organization from './shared/organization';

export const store = configureStore({
  reducer: {
    users: usersSlice.reducer,
    filters,
    toolbar,
    auth,
    organization,
    timeline: timelineSlice.reducer,
    profile: userSlice.reducer,
    timeAways,
    reportTemplates: reportTemplatesSlice.reducer,
    workflowTemplates: workflowTemplatesSlice.reducer,
    workflowInstances: workflowInstanceSlice.reducer,
    tasks: tasksSlice,
    projects,
    settings: settingsSlice,
    colorLegend,
    peoplePopup,
    organizationChart,
    dashboardCharts,
    ptoPolicies: ptoPolicySlice,
    calendars: calendarSlice,
    timesheets: timesheetsSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type State = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const getStoreState = () => store.getState();
