import { useCallback, useEffect, useState } from 'react';
import classes from './DocumentsItem.module.scss';
import { ReactComponent as AttachmentSvg } from 'assets/blue-svgs/Attachment-blue.svg';
import { useAppDispatch } from 'state/redux-hooks/reduxHooks';
import {
  updateUserDocument as updateUserDocumentAction,
  deleteUserDocument as deleteUserDocumentAction,
} from 'modules/profile/redux/userSlice';
import { type UserDocument } from 'modules/profile/models/profileModels';
import { useLocation, useParams } from 'react-router-dom';
import { SectionInfoEdit, SectionActions } from 'modules/shared/components';
import SectionInfoReadOnly from 'modules/profile/components/SectionInfoReadOnly/SectionInfoReadOnly';
import toast from 'utils/notifications/CustomToast';
import {
  deleteUserDocument,
  downloadUserDocument,
  updateUserDocument,
} from 'modules/profile/api/profile.api';
import {
  deleteOrganizationDocument,
  downloadOrganizationDocument,
  updateOrganizationDocument,
} from 'modules/settings/api/documents.api';
import {
  updateDocument as updateOrganizationDocumentAction,
  deleteDocument as deleteOrganizationDocumentAction,
} from 'modules/settings/redux/settingsSlice';
import { OrganizationDocument } from 'modules/settings/models/settingsModels';
import { ReactComponent as WorkflowTemplates } from 'assets/WorkflowTemplates.svg';
import { ActionConfirmationModal } from 'components/core';

type Props = {
  documentItem: UserDocument | OrganizationDocument;
};

export const DocumentsItem = ({ documentItem }: Props) => {
  const [openForm, setOpenForm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const [formInputs, setFormInputs] = useState<UserDocument>({
    ...documentItem,
    type:
      (documentItem as UserDocument).userDocumentType ||
      (documentItem as UserDocument).organizationDocumentType ||
      '',
  });
  const [file, setFile] = useState<File | undefined>(
    new File([], documentItem.originalFileName || ''),
  );

  const dispatch = useAppDispatch();

  const { id } = useParams();
  const { pathname } = useLocation();

  const changeAttributeHandler = (value: string, changeAttribute: string) => {
    setFormInputs((prevInputs) => ({
      ...prevInputs,
      [changeAttribute]: value,
    }));
  };

  const handleSave = () => {
    setIsSubmitted(true);

    if (file === undefined || !formInputs.name || !formInputs.type) {
      return;
    }
    const updatedDocument = {
      id: documentItem.id,
      name: formInputs.name,
      type: formInputs.type || '',
    };
    const updateDocument =
      pathname === '/settings/documents'
        ? updateOrganizationDocument(updatedDocument, file)
        : id && updateUserDocument(updatedDocument, id, file);

    const updateDocumentAction =
      pathname === '/settings/documents'
        ? updateOrganizationDocumentAction
        : updateUserDocumentAction;

    setIsLoading(true);
    updateDocument &&
      updateDocument
        .then((res) => {
          dispatch(
            updateDocumentAction({
              id: res.data.id,
              name: res.data.name,
              type: res.data.userDocumentType || '',
            }),
          );
          setOpenForm(false);
          toast.success('You have successfully updated document.');
        })
        .catch(() => {
          documentItem.originalFileName && setFile(new File([], documentItem.originalFileName));
          toast.error('Something went wrong while saving. Try again.');
        })
        .finally(() => setIsLoading(false));
  };

  const handleDownload = (shouldDownload: boolean) => {
    const downloadDocument =
      pathname === '/settings/documents'
        ? downloadOrganizationDocument(documentItem.id)
        : id && downloadUserDocument(Number(id), documentItem.id);

    setIsLoading(true);
    downloadDocument &&
      downloadDocument
        .then((res) => {
          const href = URL.createObjectURL(res.data);
          if (shouldDownload) {
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', documentItem.originalFileName || 'identification-doc');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            window.open(href, '_blank');
            URL.revokeObjectURL(href);
          }
        })
        .catch(() => toast.error('Something went wrong. Try again.'))
        .finally(() => setIsLoading(false));
  };

  const handleDelete = () => {
    const deleteDocument =
      pathname === '/settings/documents'
        ? deleteOrganizationDocument(documentItem.id)
        : id && deleteUserDocument(Number(id), documentItem.id);

    const deleteDocumentAction =
      pathname === '/settings/documents'
        ? deleteOrganizationDocumentAction
        : deleteUserDocumentAction;

    setIsLoading(true);
    deleteDocument &&
      deleteDocument
        .then(() => {
          dispatch(deleteDocumentAction(documentItem.id));
          setOpenDialog(false);
          toast.success('You have successfully deleted document.');
        })
        .catch(() => toast.error('Something went wrong while deleting document. Try again.'))
        .finally(() => setIsLoading(false));
  };

  const handleCancel = useCallback(() => {
    setIsSubmitted(false);
    setOpenForm(false);
    setFile(new File([], documentItem.originalFileName || ''));
    setFormInputs(documentItem);
  }, [documentItem]);

  useEffect(() => {
    if (documentItem.originalFileName && !file) {
      const fileName = documentItem.originalFileName;
      const newFile = new File([], fileName);
      setFile(newFile);
    }
  }, [documentItem.originalFileName, file]);

  const handleDeleteAction = () => {
    setOpenDialog(true);
  };
  return (
    <div className={classes['c-documents-item']}>
      <div className={classes['c-documents-item__header']}>
        <h2 className={classes['c-documents-item__title']}>
          {documentItem.name.toLocaleLowerCase().replace('_', ' ')}
        </h2>
        <SectionActions
          isEditOpen={openForm}
          handleEdit={() => setOpenForm(true)}
          handlePreview={() => handleDownload(false)}
          handleDownload={() => handleDownload(true)}
          handleCancel={handleCancel}
          handleSave={handleSave}
          handleDelete={handleDeleteAction}
          isLoading={isLoading}
        />
      </div>
      <div className={classes['c-documents-item__content']}>
        <SectionInfoEdit
          name="Type"
          data={formInputs.type}
          icon={<AttachmentSvg />}
          setValue={(event) => {
            changeAttributeHandler(event, 'type');
          }}
          readOnly={!openForm}
          required
          isSubmitted={isSubmitted}
        />
        {openForm ? (
          <SectionInfoEdit
            name="Name"
            data={formInputs.name}
            icon={<WorkflowTemplates />}
            setValue={(event) => {
              changeAttributeHandler(event, 'name');
            }}
            isSubmitted={isSubmitted}
            required
          />
        ) : (
          <SectionInfoReadOnly
            icon={<AttachmentSvg />}
            name="File"
            data={documentItem.originalFileName || ''}
            type="file"
            handlePreview={() => handleDownload(false)}
            handleDownload={() => handleDownload(true)}
            isLoading={isLoading}
          />
        )}
      </div>
      <ActionConfirmationModal
        message={`you want to delete "${documentItem.originalFileName}" document?`}
        isModalOpen={openDialog}
        isLoading={isLoading}
        handleNo={() => setOpenDialog(false)}
        handleYes={handleDelete}
        closeModal={() => setOpenDialog(false)}
      />
    </div>
  );
};
