import { SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import classes from './PeoplePopup.module.scss';
import { UserType } from 'config/constants';
import { emailRegex } from 'utils/regexs';
import { createActiveUser, createNewHire, promoteNewHireUser } from 'modules/people/api/people.api';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { addNewUser, promoteUser, removeUser } from 'modules/people/redux/peopleSlice';
import { getAllDepartments, getAllJobTitles, getAllLocations } from '../redux/peoplePopupActions';
import type {
  User,
  NewMember,
  ActiveMember,
  DirectReports,
  NewActiveMember,
  UserBaseInfo,
} from 'modules/people/model/User';
import { Divider, Checkbox, Input, toast, Button, AddMemberDropDown } from 'components/core';
import SelectionButton from './SelectionButton/SelectionButton';
import { formatDateForInput } from 'utils';
import { UserSearchInputComponent } from './UserSearchInputComponent/UserSearchInputComponent';

type EmailErrors = {
  activeMemberEmail: string;
  newMemberPersonalEmail: string;
};

type Props = {
  userToPromoteId?: number;
  selectedUserType: UserType;
  setIsPopupOpen: (value: boolean) => void;
  newUser?: NewMember;
  activeMember?: ActiveMember;
  setNewUser?: React.Dispatch<SetStateAction<NewMember>>;
  setActiveMember?: React.Dispatch<SetStateAction<ActiveMember>>;
};

const defaultActiveMember = {
  firstName: '',
  lastName: '',
  email: '',
  jobTitle: '',
  department: '',
  reportTo: '',
  startDate: new Date(),
  firstWorkingDay: new Date(),
  location: '',
};

const defaultNewUser = {
  firstName: '',
  lastName: '',
  personalEmail: '',
  startDate: new Date(),
  jobTitle: '',
};

export const PeoplePopup = ({
  userToPromoteId,
  selectedUserType,
  setIsPopupOpen,
  newUser,
  setNewUser,
  activeMember,
  setActiveMember,
}: Props) => {
  const [userType, setUserType] = useState(UserType.ACTIVE_MEMBER);

  const [emailErrors, setEmailErrors] = useState<EmailErrors>({
    activeMemberEmail: '',
    newMemberPersonalEmail: '',
  });
  const { users } = useAppSelector((state) => state.users);
  const { jobTitles, locations, departments } = useAppSelector((state) => state.peoplePopup);
  const [isChecked, setIsChecked] = useState<boolean>(true);
  const [selectedUser, setSelectedUser] = useState<number>();
  const [reportingToUser, setReportingToUser] = useState<UserBaseInfo | undefined>();

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const locationListDropDown = useMemo(() => {
    return locations.map((location) => ({
      id: location.id,
      location: `${location.city}${location.address ? `, ${location.address}` : ''}`,
    }));
  }, [locations]);

  const dispatch = useAppDispatch();
  const isActiveMemberActive = userType === UserType.ACTIVE_MEMBER;

  const changeNewUser = (value: string, prop: string) => {
    setNewUser?.((prevUser: NewMember) => ({ ...prevUser, [prop]: value }));
  };

  const changeActiveMember = (value: string, prop: string) => {
    setActiveMember?.((prevUser: ActiveMember) => ({ ...prevUser, [prop]: value }));
  };

  const areInputsValid = (member: ActiveMember | NewMember) => {
    const hasInvalidInput = Object.entries(member).some(([key, input]) => {
      if (key === 'reportTo') return false;

      return (typeof input === 'string' && input.trim() === '') || !input;
    });

    return !hasInvalidInput;
  };

  const areEmailsValid = useCallback(() => {
    if (activeMember?.email && !activeMember.email.match(emailRegex)) {
      setEmailErrors((prevEmailErrors) => ({
        ...prevEmailErrors,
        activeMemberEmail: 'Please enter a valid email format.',
      }));
    } else
      setEmailErrors((prevEmailErrors) => ({
        ...prevEmailErrors,
        activeMemberEmail: '',
      }));

    if (newUser?.personalEmail && !newUser.personalEmail.match(emailRegex)) {
      setEmailErrors((prevEmailErrors) => ({
        ...prevEmailErrors,
        newMemberPersonalEmail: 'Please enter a valid email format.',
      }));
    } else
      setEmailErrors((prevEmailErrors) => ({
        ...prevEmailErrors,
        newMemberPersonalEmail: '',
      }));
  }, [activeMember?.email, newUser?.personalEmail]);

  const getSelectedLocation = useCallback(() => {
    return locations.find(({ id }) => id === Number(activeMember?.location));
  }, [activeMember]);

  const handlePromoteMember = useCallback(async () => {
    setIsFormSubmitted(true);

    if (!activeMember) return;
    if (!areInputsValid(activeMember) || !userToPromoteId) return;

    setIsLoading(true);

    try {
      const { firstName, lastName, email, jobTitle, department, startDate, firstWorkingDay } =
        activeMember;

      const { data } = await promoteNewHireUser({
        id: userToPromoteId,
        firstName,
        lastName,
        userStatus: UserType.ACTIVE_MEMBER,
        email,
        jobTitle,
        department,
        organizationLocationDto: getSelectedLocation(),
        userReportingToId: selectedUser,
        startDate: formatDateForInput(startDate || ''),
        firstWorkingDay: formatDateForInput(firstWorkingDay || ''),
      });

      if (selectedUserType === UserType.NEW_HIRE) {
        dispatch(removeUser(userToPromoteId));
      } else {
        const placeOfWork = `${data.organizationLocationDto?.address}-${data.organizationLocationDto?.city}`;
        const country = data.organizationLocationDto?.country;

        dispatch(
          promoteUser({
            ...data,
            placeOfWork,
            reportingToUser,
            country,
            effectiveOn: data.startDate,
            userType: data.userStatus,
          }),
        );
      }

      setIsPopupOpen(false);
      setActiveMember?.(defaultActiveMember);
      toast('success', 'You have successfully promoted user to active member.');
    } catch {
      toast('error', 'Something went wrong while promoting user to active member. Try again.');
    } finally {
      setIsLoading(false);
    }
  }, [
    activeMember,
    dispatch,
    getSelectedLocation,
    setIsPopupOpen,
    setReportingToUser,
    setSelectedUser,
    userToPromoteId,
  ]);

  const handleAddActiveMember = useCallback(async () => {
    const location = getSelectedLocation();

    setIsFormSubmitted(true);

    if (!activeMember) return;
    if (!areInputsValid(activeMember)) return;

    setIsLoading(true);

    const newActiveMember: NewActiveMember = {
      firstName: activeMember.firstName,
      lastName: activeMember.lastName,
      userStatus: UserType.ACTIVE_MEMBER,
      email: activeMember.email || '',
      jobTitle: activeMember.jobTitle,
      department: activeMember.department || '',
      userReportingToId: selectedUser,
      startDate: formatDateForInput(activeMember.startDate || ''),
      firstWorkingDay: formatDateForInput(activeMember.firstWorkingDay || ''),
      organizationLocationDto: location,
    };

    try {
      const { data } = await createActiveUser(newActiveMember);
      if (selectedUserType !== UserType.NEW_HIRE) {
        const directReports: DirectReports[] = [];
        let reportingToUserReports: DirectReports | undefined;

        if (reportingToUser) {
          const { id, firstName, lastName, jobTitle, profilePhotoPath } = reportingToUser;

          reportingToUserReports = {
            id,
            firstName,
            lastName,
            jobTitle: jobTitle || '',
            profilePicture: profilePhotoPath || '',
          };
        }

        const newMember: User = {
          ...data,
          placeOfWork: `${data.organizationLocationDto.address}-${data.organizationLocationDto.city}`,
          country: data.organizationLocationDto.country,
          effectiveOn: data.firstWorkingDay,
          directReports,
          reportingToUser: reportingToUserReports,
          userType: UserType.ACTIVE_MEMBER,
          newestAddedUser: true,
        };

        dispatch(addNewUser(newMember));
      }

      setActiveMember?.(defaultActiveMember);

      setIsPopupOpen(false);
      toast('success', 'You have successfully added an active member to your staff.');
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data.message) {
        toast('error', error.response?.data.message);
        return;
      }

      toast('error', 'Something went wrong while adding active member. Try again.');
    } finally {
      setIsLoading(false);
    }
  }, [activeMember, dispatch, getSelectedLocation, locations, setIsPopupOpen, users]);

  const handleAddNewHire = useCallback(async () => {
    setIsFormSubmitted(true);
    if (!newUser) return;
    if (!areInputsValid(newUser)) return;

    try {
      setIsLoading(true);
      const { data } = await createNewHire(
        newUser.firstName,
        newUser.lastName,
        newUser.personalEmail,
        newUser.jobTitle,
        formatDateForInput(newUser.startDate),
      );

      if (selectedUserType !== UserType.ACTIVE_MEMBER) {
        const newMember = {
          ...data,
          email: data.personalEmail,
          userType: UserType.NEW_HIRE,
          effectiveOn: data.startDate,
          newestAddedUser: true,
        };

        dispatch(addNewUser(newMember));
      }

      setNewUser?.(defaultNewUser);
      setIsPopupOpen(false);
      toast('success', 'You have successfully added a new member to your staff.');
    } catch {
      toast('error', 'Something went wrong while adding new member. Try again.');
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, locations, newUser, setIsPopupOpen, setReportingToUser, setSelectedUser]);

  useEffect(() => {
    areEmailsValid();
  }, [activeMember, areEmailsValid, newUser]);

  useEffect(() => {
    setIsFormSubmitted(false);
  }, [userType]);

  useEffect(() => {
    dispatch(getAllJobTitles());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllDepartments());
  }, [dispatch]);

  useEffect(() => {
    !locations.length && dispatch(getAllLocations());
  }, [dispatch, locations.length]);

  useEffect(() => {
    if (!activeMember) return;
    if (isChecked && activeMember.firstWorkingDay !== activeMember.startDate) {
      setActiveMember?.((prevState) => ({ ...prevState, firstWorkingDay: activeMember.startDate }));
    }
  }, [activeMember, isChecked]);

  const renderFormHeader = useCallback(() => {
    if (userToPromoteId) return;

    return (
      <div className={classes['c-people-popup__buttons']}>
        <SelectionButton
          label="Active member"
          selected={isActiveMemberActive}
          onClick={() => setUserType(UserType.ACTIVE_MEMBER)}
        />
        <span className={classes['c-people-popup__or']}>or</span>
        <SelectionButton
          label="New hire"
          selected={!isActiveMemberActive}
          onClick={() => setUserType(UserType.NEW_HIRE)}
        />
      </div>
    );
  }, [isActiveMemberActive, userToPromoteId]);

  const renderFormButtons = () => {
    if (userToPromoteId) {
      return (
        <Button size="medium" isLoading={isLoading} onClick={handlePromoteMember}>
          Promote member
        </Button>
      );
    }

    if (userType === UserType.ACTIVE_MEMBER) {
      return (
        <Button size="medium" isLoading={isLoading} onClick={handleAddActiveMember}>
          Add active member
        </Button>
      );
    }

    return (
      <Button size="medium" isLoading={isLoading} onClick={handleAddNewHire}>
        Add new hire
      </Button>
    );
  };

  const optionalText = (
    <span className={classes['c-people-popup__optional-text']}> (optional)</span>
  );
  return (
    <div className={classes['c-people-popup']}>
      <>{renderFormHeader()}</>
      <Divider type="light" />
      {userType === UserType.ACTIVE_MEMBER ? (
        <form className={classes['c-people-popup__form']}>
          <div className={classes['c-people-popup__multiple-inputs']}>
            <Input
              size="medium"
              autoFocus
              name="firstName"
              id="firstName"
              label="First name"
              value={activeMember?.firstName}
              setValue={(event) => changeActiveMember(event, 'firstName')}
              isSubmitted={isFormSubmitted}
            />
            <Input
              size="medium"
              name="lastName"
              id="lastName"
              label="Last name"
              setValue={(event) => changeActiveMember(event, 'lastName')}
              value={activeMember?.lastName}
              isSubmitted={isFormSubmitted}
            />
          </div>
          <Input
            size="medium"
            name="email"
            id="email"
            value={activeMember?.email}
            errorMessage={isFormSubmitted ? emailErrors.activeMemberEmail : ''}
            label="Work email"
            setValue={(event) => changeActiveMember(event, 'email')}
            isSubmitted={isFormSubmitted}
          />
          <AddMemberDropDown
            label="Job title"
            items={jobTitles.map((value) => ({ value }))}
            id="jobTitle"
            setValue={(event) => changeActiveMember(event, 'jobTitle')}
            selectedValue={activeMember?.jobTitle}
            isSubmitted={isFormSubmitted}
          />
          <Input
            size="medium"
            name="startDate"
            id="startDate"
            value={formatDateForInput(activeMember?.startDate || '')}
            type="date"
            label="Start date"
            setValue={(event) => changeActiveMember(event, 'startDate')}
          />
          <AddMemberDropDown
            label="Department"
            items={departments.map((value, index) => ({ id: index, value }))}
            id="department"
            setValue={(event) => changeActiveMember(event, 'department')}
            selectedValue={activeMember?.department}
            isSubmitted={isFormSubmitted}
          />
          <UserSearchInputComponent
            label={
              <>
                Reporting to
                {optionalText}
              </>
            }
            id="reportTo"
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            setUser={setReportingToUser}
          />
          <AddMemberDropDown
            label="Location"
            items={locationListDropDown.map(({ id, location }) => ({
              id,
              value: location,
            }))}
            id="location"
            setSelectedItemId={(locationId) => changeActiveMember(String(locationId), 'location')}
            selectedValue={
              locationListDropDown.find(
                (location) => location.id === Number(activeMember?.location),
              )?.location
            }
            isSubmitted={isFormSubmitted}
          />
          {!isChecked && (
            <Input
              size="medium"
              name="firstWorkingDay"
              id="firstWorkingDay"
              value={formatDateForInput(activeMember?.firstWorkingDay || '')}
              type="date"
              label="First working day"
              setValue={(event) => changeActiveMember(event, 'firstWorkingDay')}
              min={new Date()}
            />
          )}
          <div className={classes['c-people-popup__checkbox-container']}>
            <Checkbox
              checked={isChecked}
              label="First working day same as start date"
              labelColor="#3f4c4f"
              id={0}
              handleCheckedItem={() => {
                setIsChecked((prevState) => !prevState);
                changeActiveMember(activeMember?.startDate?.toString() || '', 'firstWorkingDay');
              }}
            />
          </div>
        </form>
      ) : (
        <form className={classes['c-people-popup__form']}>
          <div className={classes['c-people-popup__multiple-inputs']}>
            <Input
              size="medium"
              value={newUser?.firstName}
              autoFocus
              name="firstName"
              id="firstName"
              label="First name"
              setValue={(event) => changeNewUser(event, 'firstName')}
              isSubmitted={isFormSubmitted}
            />
            <Input
              size="medium"
              name="lastName"
              id="lastName"
              label="Last name"
              value={newUser?.lastName}
              setValue={(event) => changeNewUser(event, 'lastName')}
              isSubmitted={isFormSubmitted}
            />
          </div>
          <Input
            size="medium"
            name="personalEmail"
            id="personalEmail"
            label="Personal email"
            value={newUser?.personalEmail}
            errorMessage={isFormSubmitted ? emailErrors.newMemberPersonalEmail : ''}
            setValue={(event) => changeNewUser(event, 'personalEmail')}
            isSubmitted={isFormSubmitted}
          />
          <AddMemberDropDown
            label="Job title"
            items={jobTitles.map((value) => ({ value }))}
            id="jobTitle"
            setValue={(event) => changeNewUser(event, 'jobTitle')}
            selectedValue={newUser?.jobTitle}
            isSubmitted={isFormSubmitted}
          />
          <Input
            size="medium"
            name="startDate"
            id="startDate"
            type="date"
            label="Start date"
            value={formatDateForInput(newUser?.startDate || '')}
            setValue={(event) => changeNewUser(event, 'startDate')}
            min={new Date()}
          />
        </form>
      )}
      <div className={classes['c-people-popup__bottom-container']}>{renderFormButtons()}</div>
    </div>
  );
};
