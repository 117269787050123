import { useEffect, useState } from 'react';
import classes from './Documents.module.scss';
import { DocumentDropSection, Status } from 'components/core';
import { OrganizationDocument } from 'modules/settings/models/settingsModels';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { getOrganizationDocuments } from 'modules/settings/redux/settingsActions';
import { DocumentsItem } from 'modules/shared/components';
import { useToolbarTitle } from 'hooks';
import { RequestState } from 'config/constants';

const Documents = () => {
  const { documents, loading } = useAppSelector((state) => state.settings);

  const dispatch = useAppDispatch();

  useToolbarTitle({ title: ['Settings', 'Documents'] });

  const [inputs, setInputs] = useState<OrganizationDocument>({
    id: 0,
    name: '',
    type: '',
  });

  useEffect(() => {
    dispatch(getOrganizationDocuments());
  }, [dispatch]);

  return (
    <Status isLoading={loading.documents !== RequestState.FULFILLED}>
      <div className={classes['c-documents']}>
        {!!documents.length && (
          <div className={classes['c-documents__section-container']}>
            {documents.map((document) => (
              <DocumentsItem documentItem={document} key={document.id} />
            ))}
          </div>
        )}
        <DocumentDropSection
          title="Add document"
          documentType="organization"
          documentInputs={inputs}
          handleUserDocumentInputs={setInputs}
        />
      </div>
    </Status>
  );
};

export default Documents;
