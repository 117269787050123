import { useCallback, useEffect, useState } from 'react';
import classes from '../../../styles/Section.module.scss';
import { ReactComponent as UserSvg } from 'assets/PersonIcon.svg';
import { ReactComponent as AddressSvg } from 'assets/LocationIcon.svg';
import { ReactComponent as MailSvg } from 'assets/MailIcon.svg';
import { ReactComponent as PhoneSvg } from 'assets/PhoneIcon.svg';
import { ReactComponent as CalendarSvg } from 'assets/CalendarIcon.svg';
import { SectionInfoEdit, SectionActions } from 'modules/shared/components';
import { User } from 'modules/profile/models/profileModels';
import { emailRegex } from 'utils/regexs';
import { useAppDispatch } from 'state/redux-hooks/reduxHooks';
import { updateUserBasics } from 'modules/profile/redux/userSlice';
import { updateUser } from 'modules/profile/api/profile.api';
import { getCustomFieldsByPosition } from 'modules/profile/utils/customFields';
import { formatDateForInput } from 'utils/dates';
import { toast } from 'components/core';
import { UserGender } from 'config/constants';
import { CustomFieldItem } from '../../CustomFieldItem/CustomFieldItem';
import { useUserCustomFields } from 'modules/profile/hooks';

type RequiredErrors = {
  firstName: boolean;
  lastName: boolean;
};

type Props = {
  user: User;
};

const Basics = ({ user }: Props) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState<User>(user);
  const [emailErrorMessage, setEmailErrorMessage] = useState<string>('');

  const [requiredFields, setRequiredFields] = useState<RequiredErrors>({
    firstName: false,
    lastName: false,
  });

  const { customFields } = useUserCustomFields(user.basicCustomFields, isEditMode);

  const isEmailInvalid =
    isEditMode && !!userData.personalEmail && !emailRegex.test(userData.personalEmail);

  const dispatch = useAppDispatch();

  const handleEdit = async () => {
    setIsEditMode(true);
  };

  const handleOnChange = (value: string, key: keyof User) => {
    setUserData((previous) => ({ ...previous, [key]: value }));
  };

  const handleOnCustomFieldChange = (value: string, id: number) => {
    setUserData((previous) => ({
      ...previous,
      basicCustomFields: previous.basicCustomFields.map((field) =>
        field.customFieldId === id ? { ...field, fieldValue: value } : field,
      ),
    }));
  };

  const getCustomFieldValue = (id: number) => {
    return userData.basicCustomFields.find((field) => field.customFieldId === id)?.fieldValue ?? '';
  };

  const requiredFieldsMissing = useCallback(() => {
    const errors = { ...requiredFields };

    Object.entries(errors).forEach(([key]) => {
      const prop = key as keyof User;
      const errorProp = key as keyof RequiredErrors;

      if (!userData[prop]) return true;

      errors[errorProp] = !userData[prop].toString().length;
    });

    setRequiredFields(errors);

    return Object.entries(errors).some(([, val]) => val);
  }, [requiredFields, userData]);

  const submitChangesHandler = useCallback(async () => {
    if (requiredFieldsMissing()) return;

    if (isEmailInvalid) {
      setEmailErrorMessage('Please enter the valid email format');
      return;
    }

    try {
      setIsLoading(true);

      const { data } = await updateUser({
        id: userData.id,
        firstName: userData.firstName,
        lastName: userData.lastName,
        userStatus: userData.userStatus,
        gender: userData.gender,
        personalEmail: userData.personalEmail,
        email: userData.email,
        address: userData.address,
        nationalities: userData.nationalities,
        dateOfBirth: userData.dateOfBirth,
        personalPhone: userData.personalPhone,
        basicCustomFields: userData.basicCustomFields,
      });

      dispatch(updateUserBasics(data));
      setIsEditMode(false);
      toast('success', 'You have successfully updated basic info.');
    } catch {
      toast('error', 'Something went wrong while updating basic info. Try again.');
    } finally {
      setEmailErrorMessage('');
      setIsLoading(false);
    }
  }, [requiredFieldsMissing, isEmailInvalid, userData, dispatch]);

  const handleCancel = useCallback(() => {
    setEmailErrorMessage('');
    setUserData(user);
    setIsEditMode(false);
  }, [user]);

  useEffect(() => {
    setUserData(user);
  }, [user]);

  return (
    <div className={classes['c-section']}>
      <div className={classes['c-section__header']}>
        <h2 className={classes['c-section__title']}>Basic</h2>
        <SectionActions
          isEditOpen={isEditMode}
          handleEdit={handleEdit}
          handleCancel={handleCancel}
          handleSave={submitChangesHandler}
          isLoading={isLoading}
        />
      </div>
      <div className={classes['c-section__content--columns']}>
        <div className={classes['c-section__content-column']}>
          <SectionInfoEdit
            name="First name"
            data={userData.firstName}
            icon={<UserSvg />}
            isInvalid={requiredFields.firstName}
            required
            setValue={(value) => handleOnChange(value, 'firstName')}
            autoFocus
            readOnly={!isEditMode}
          />
          <SectionInfoEdit
            name="Last name"
            data={userData.lastName}
            icon={<UserSvg />}
            isInvalid={requiredFields.lastName}
            required
            setValue={(value) => handleOnChange(value, 'lastName')}
            readOnly={!isEditMode}
          />
          <SectionInfoEdit
            name="Address"
            data={userData.address}
            icon={<AddressSvg />}
            setValue={(value) => handleOnChange(value, 'address')}
            readOnly={!isEditMode}
          />
          <SectionInfoEdit name="Work email" data={user.email} icon={<MailSvg />} readOnly />
          <SectionInfoEdit
            name="Personal email"
            data={userData.personalEmail}
            icon={<MailSvg />}
            isInvalid={isEmailInvalid}
            setValue={(value) => handleOnChange(value, 'personalEmail')}
            errorMessage={emailErrorMessage}
            readOnly={!isEditMode}
          />
          {getCustomFieldsByPosition(customFields, 'odd').map((customField) => (
            <CustomFieldItem
              customField={customField}
              key={customField.customFieldId}
              readonly={!isEditMode}
              selectedOption={getCustomFieldValue(customField.customFieldId)}
              onChange={(option) => handleOnCustomFieldChange(option, customField.customFieldId)}
            />
          ))}
        </div>
        <div className={classes['c-section__content-column']}>
          <SectionInfoEdit
            name="Personal phone"
            data={userData.personalPhone}
            icon={<PhoneSvg />}
            setValue={(value) => handleOnChange(value, 'personalPhone')}
            readOnly={!isEditMode}
          />
          <SectionInfoEdit
            name="Date of birth"
            data={userData.dateOfBirth ? formatDateForInput(userData.dateOfBirth) : ''}
            icon={<CalendarSvg />}
            inputType="date"
            max={new Date()}
            setValue={(value) => handleOnChange(formatDateForInput(value), 'dateOfBirth')}
            readOnly={!isEditMode}
          />
          <SectionInfoEdit
            name="Nationality"
            data={userData.nationalities}
            icon={<UserSvg />}
            setValue={(value) => handleOnChange(value, 'nationalities')}
            readOnly={!isEditMode}
          />
          <SectionInfoEdit
            showAsDropdown
            name="Gender"
            data={userData.gender}
            icon={<UserSvg />}
            dropdownOptions={Object.keys(UserGender)}
            dropdownDisplayedOptions={['Male', 'Female', 'Other']}
            setValue={(option) => handleOnChange(option, 'gender')}
            handleDropdownChange={(option) => handleOnChange(option, 'gender')}
            selectedOption={user.gender}
            readOnly={!isEditMode}
          />
          {getCustomFieldsByPosition(customFields, 'even').map((customField) => (
            <CustomFieldItem
              customField={customField}
              key={customField.customFieldId}
              readonly={!isEditMode}
              selectedOption={getCustomFieldValue(customField.customFieldId)}
              onChange={(option) => handleOnCustomFieldChange(option, customField.customFieldId)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Basics;
