export type TimeAwayTypeCategory = 'WORKING_AWAY' | 'TIME_OFF';

export enum TimeAwayStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
}

export type TimeAwayType = {
  id: number;
  name: string;
  organizationId: number;
  timeAwayCategory: TimeAwayTypeCategory;
  color: string;
  enabled: boolean;
};

export type NewTimeAwayType = Omit<TimeAwayType, 'id' | 'organizationId'>;

export type TimeAway = {
  id?: number;
  startDate: string;
  timeAwayStartPeriod: string;
  endDate: string;
  timeAwayEndPeriod: string;
  note: string;
  timeAwayType: TimeAwayType;
  userId: number;
  timeAwayStatus: TimeAwayStatus;
};
