import { useCallback, useState } from 'react';
import { NavLink } from 'react-router-dom';
import classes from '../styles/ProfileNav.module.scss';
import { ReactComponent as UserIcon } from 'assets/UserIcon.svg';
import { ReactComponent as IdentificationIcon } from 'assets/IdentificationIcon.svg';
import { ReactComponent as Briefcase } from 'assets/BriefcaseIcon.svg';
import { ReactComponent as FileIcon } from 'assets/File.svg';
import { ReactComponent as PhoneIcon } from 'assets/PhoneIcon.svg';
import { ReactComponent as OffIcon } from 'assets/OffIcon.svg';
import { useAppSelector } from 'state/redux-hooks/reduxHooks';
import {
  PopUpModal,
  Avatar,
  Divider,
  ProgressBar,
  toast,
  ActionConfirmationModal,
} from 'components/core';
import OffboardingModal from './Modals/Offboarding/OffboardingModal';
import { selectUser } from '../redux/userSlice';
import { Role } from 'config/constants';
import ProfileNavLink from './ProfileNavLink';
import { resendVerificationToken } from '../api/profile.api';

type Props = {
  userId?: string;
  onAvatarUpload: () => void;
};

const ProfileNav = ({ userId, onAvatarUpload }: Props) => {
  const [isOffBoardingPopupOpen, setIsOffBoardingPopupOpen] = useState<boolean>(false);

  const user = useAppSelector(selectUser);
  const authUserId = useAppSelector((state) => state.auth.user?.id);
  const userRole = useAppSelector((state) => state.auth.user?.role);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const checkFirstBankAccount = () => {
    if (!user.bankAccounts && !user.bankAccounts[0]) {
      return;
    }

    return (
      !user.bankAccounts[0].bankName ||
      !user.bankAccounts[0].accountHolderName ||
      !user.bankAccounts[0].accountNumber
    );
  };

  const checkProfileInfoHandler = () => {
    return (
      !user.firstName ||
      !user.lastName ||
      !user.email ||
      !user.personalEmail ||
      !user.address ||
      !user.personalPhone ||
      !user.dateOfBirth ||
      !user.nationalities ||
      !user.gender ||
      !user.bankAccounts.length ||
      checkFirstBankAccount()
    );
  };

  const checkEmergencyContacts = () => {
    const containingList: boolean[] = user.emergencyContacts.map((contact) => {
      if (!contact.email || !contact.fullName || !contact.phone || !contact.relationship) {
        return true;
      }
      return false;
    });

    return containingList.includes(true);
  };

  const getEmergencyContactsBadgeTitle = () => {
    if (!user.emergencyContacts.length) return 'No contacts';
    if (checkEmergencyContacts()) return 'Missing info';

    return null;
  };

  const emergencyContactsBadgeTitle = getEmergencyContactsBadgeTitle();

  const handleShowDialog = useCallback(() => {
    setOpenDialog(!openDialog);
  }, [openDialog]);

  const handleResendVerificationToken = useCallback(async () => {
    setIsLoading(true);

    try {
      await resendVerificationToken(user.email);
      handleShowDialog();
      toast('success', 'You successfully resend verification token.');
    } catch {
      toast('error', 'Something went wrong while resending verification token. Try again.');
    } finally {
      setIsLoading(false);
    }
  }, [handleShowDialog, user.email]);

  const renderButton = useCallback(() => {
    if (
      (userRole === Role.SUPER_ADMINISTRATOR || userRole === Role.ADMINISTRATOR) &&
      !user.firstTimeLogin &&
      user.email !== null &&
      authUserId !== user.id
    )
      return (
        <button
          className={`${classes['c-profile-nav__link--secondary']} ${classes['c-profile-nav__link--resend']}`}
          onClick={handleShowDialog}
        >
          Resend Verification Token
        </button>
      );
    return (
      <NavLink
        className={({ isActive }) =>
          `${classes['c-profile-nav__link--secondary']} ${
            isActive ? classes['c-profile-nav__link--secondary-active'] : ''
          }`
        }
        to="time-away"
      >
        Manage time away
      </NavLink>
    );
  }, [authUserId, handleShowDialog, user.email, user.firstTimeLogin, user.id, userRole]);

  const renderOffboardingLink = useCallback(() => {
    if (
      !(userRole == Role.ADMINISTRATOR || userRole === Role.SUPER_ADMINISTRATOR) ||
      authUserId === user.id
    )
      return;

    if (user.userStatus === 'OFFBOARDING_SCHEDULED')
      return (
        <ProfileNavLink
          url={`/user/${userId}/offboarding`}
          icon={<OffIcon className={classes['c-profile-nav__icon']} />}
          text="Offboarding"
        />
      );

    return (
      <button
        className={classes['c-profile-nav__link-holder']}
        onClick={() => setIsOffBoardingPopupOpen(true)}
      >
        <div className={classes['c-profile-nav__link']}>
          <OffIcon className={classes['c-profile-nav__icon']} />
          <span className={classes['c-profile-nav__link-text']}>Offboarding</span>
        </div>
      </button>
    );
  }, [userRole, authUserId, user.id, user.userStatus, userId]);

  return (
    <div className={classes['c-profile-nav']}>
      <div className={classes['c-profile-nav__header']} />
      <div className={classes['c-profile-nav__user-details']}>
        <div className={classes['c-profile-nav__user-avatar']} onClick={onAvatarUpload}>
          <Avatar
            size="medium"
            imageUrl={user.profilePhotoPath}
            title={`${user.firstName} ${user.lastName}`}
          />

          <label
            htmlFor="profilePhotoUpload"
            className={classes['c-profile-nav__user-avatar-input-label']}
          />
          <span className={classes['c-profile-nav__data']}>
            {user.employment.employmentRole.jobTitle ?? 'N/A'}
          </span>
        </div>
        <Divider type="light" />
        <div className={classes['c-profile-nav__profile-compliteness']}>
          <div className={classes['c-profile-nav__profile-compliteness-text']}>
            Profile completeness
            <span className={classes['c-profile-nav__profile-compliteness-text--percentage']}>
              {Math.round(user.profileCompleteness)}%
            </span>
          </div>
          <ProgressBar color="#79de5f" completed={`${user.profileCompleteness}`} barHeight={10} />
        </div>
        <Divider type="light" />
        <div className={classes['c-profile-nav__section']}>{renderButton()}</div>
      </div>
      <Divider type="light" />
      <div className={classes['c-profile-nav__links']}>
        <ProfileNavLink
          url={`/user/${userId}/about`}
          icon={<UserIcon className={classes['c-profile-nav__icon']} />}
          text="Profile"
          error={checkProfileInfoHandler()}
        />
        <ProfileNavLink
          url={`/user/${userId}/employment`}
          icon={<Briefcase className={classes['c-profile-nav__icon']} />}
          text="Employment"
        />
        <ProfileNavLink
          url={`/user/${userId}/identification`}
          icon={<IdentificationIcon className={classes['c-profile-nav__icon']} />}
          text="Identification"
        />
        <ProfileNavLink
          url={`/user/${userId}/documents`}
          icon={<FileIcon className={classes['c-profile-nav__icon']} />}
          text="Documents"
        />
        <ProfileNavLink
          url={`/user/${userId}/emergency-contacts`}
          icon={<PhoneIcon className={classes['c-profile-nav__icon']} />}
          text="Emergency contacts"
          tooltip={getEmergencyContactsBadgeTitle()?.toString()}
          error={!!emergencyContactsBadgeTitle}
        />
        {renderOffboardingLink()}
      </div>
      {isOffBoardingPopupOpen && (
        <PopUpModal
          title="Offboarding"
          opened={isOffBoardingPopupOpen}
          closeModal={() => setIsOffBoardingPopupOpen(false)}
          width={30}
          top="30%"
        >
          <OffboardingModal setIsModalOpen={setIsOffBoardingPopupOpen} />
        </PopUpModal>
      )}
      <ActionConfirmationModal
        message="you want to resend verification token?"
        isModalOpen={openDialog}
        closeModal={handleShowDialog}
        isLoading={isLoading}
        handleYes={handleResendVerificationToken}
        handleNo={handleShowDialog}
      />
    </div>
  );
};

export default ProfileNav;
