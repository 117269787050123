import { Dispatch, Fragment, ReactNode, SetStateAction, useMemo, useState } from 'react';
import { type AxiosError } from 'axios';
import { PopUpModal, ActionConfirmationModal, toast } from 'components/core';
import {
  createTimeAwayType,
  deleteTimeAwayType,
  updateTimeAwayType,
} from 'modules/settings/api/timeAwayType.api';
import { TimeAwayType as TimeAwayTypeModel } from 'modules/shared/model';
import Section from '../../Section/Section';
import SectionItem from '../../Section/SectionItem';
import classes from './TimeAwayType.module.scss';
import TimeAwayTypeForm, { TimeAwayTypeFormData } from './TimeAwayTypeForm/TimeAwayTypeForm';
import { colors } from './colors';

enum Action {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

const defaultFormData: TimeAwayTypeFormData = { name: '', color: '', category: 'TIME_OFF' };

type Props = {
  timeAwayTypes: TimeAwayTypeModel[];
  setTimeAwayTypes: Dispatch<SetStateAction<TimeAwayTypeModel[]>>;
};

const TimeAwayType = ({ timeAwayTypes, setTimeAwayTypes }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [actionType, setActionType] = useState<Action>(Action.CREATE);
  const [formData, setFormData] = useState<TimeAwayTypeFormData>(defaultFormData);
  const [selectedTimeAwayType, setSelectedTimeAwayType] = useState<TimeAwayTypeModel | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const selectedColors = useMemo(
    () => timeAwayTypes.map((timeAwayType) => timeAwayType.color),
    [timeAwayTypes],
  );

  const allColors = [...new Set([...selectedColors, ...colors])];
  const disabledColors = allColors.filter(
    (color) => selectedColors.includes(color) && color !== formData.color,
  );

  const handleCreateNew = async () => {
    if (!formData.name.length || !formData.color.length) {
      toast('warning', 'Please fill all the fields.');
      return;
    }

    if (timeAwayTypes.find((timeAwayType) => timeAwayType.name === formData.name)) {
      toast('warning', 'Time away type with this name already exists.');
      return;
    }

    try {
      const response = await createTimeAwayType({
        name: formData.name,
        timeAwayCategory: formData.category,
        color: formData.color,
        enabled: true,
      });
      const { id, organizationId, name, timeAwayCategory, color } = response.data;

      setTimeAwayTypes((prev) => [
        ...prev,
        {
          id,
          name,
          organizationId,
          timeAwayCategory,
          color,
          enabled: true,
        },
      ]);
      setFormData(defaultFormData);
      handleModalClose();
      toast('success', `Time away type ${name} has been successfully created.`);
    } catch {
      toast('error', 'Something went wrong while creating new time away type, please try again.');
    }
  };

  const handleOpenOnEdit = (id: number) => {
    setActionType(Action.UPDATE);
    const timeAwayType = timeAwayTypes.find((timeAwayType) => timeAwayType.id === id);

    setFormData({
      name: timeAwayType?.name ?? '',
      category: timeAwayType?.timeAwayCategory ?? 'TIME_OFF',
      color: timeAwayType?.color ?? '',
    });
    setSelectedTimeAwayType(timeAwayType ?? null);
  };

  const handleSaveEdited = async () => {
    if (!selectedTimeAwayType) return;

    if (
      selectedTimeAwayType.name === formData.name &&
      selectedTimeAwayType.timeAwayCategory === formData.category &&
      selectedTimeAwayType.color === formData.color
    ) {
      toast('warning', 'You have not made any changes.');
      return;
    }

    try {
      const response = await updateTimeAwayType({
        id: selectedTimeAwayType.id,
        name: formData.name,
        organizationId: selectedTimeAwayType.organizationId,
        timeAwayCategory: formData.category,
        color: formData.color,
        enabled: true,
      });
      const { name, color, timeAwayCategory } = response.data;

      setTimeAwayTypes([
        ...timeAwayTypes.filter((timeAwayType) => timeAwayType.id !== selectedTimeAwayType.id),
        {
          ...selectedTimeAwayType,
          name,
          color,
          timeAwayCategory,
        },
      ]);
      handleModalClose();
      toast('success', `Time away type ${name} has been successfully edited.`);
    } catch {
      toast('error', 'Something went wrong while saving edited time away type, please try again.');
    }
  };

  const handleOpenDeleteModal = async (id: number) => {
    setActionType(Action.DELETE);
    setIsModalOpen(true);

    const timeAwayType = timeAwayTypes.find((timeAwayType) => timeAwayType.id === id);
    setSelectedTimeAwayType(timeAwayType ?? null);
  };

  const handleOnDelete = async () => {
    if (!selectedTimeAwayType?.id) return;

    const { id } = selectedTimeAwayType;

    try {
      setIsLoading(true);
      await deleteTimeAwayType(id);
      const name = timeAwayTypes.find((timeAwayType) => timeAwayType.id === id)?.name ?? '';
      setTimeAwayTypes(timeAwayTypes.filter((timeAwayType) => timeAwayType.id !== id));
      handleModalClose();
      toast('success', `Time away type ${name} has been successfully deleted.`);
    } catch (error) {
      const { response } = error as AxiosError;
      if (response?.status === 400) {
        toast('error', 'Can not delete time away type that is in use.');
        return;
      }

      toast('error', 'Something went wrong while deleting time away type, please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleModalOpen = () => {
    setActionType(Action.CREATE);
    setFormData(defaultFormData);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setActionType(Action.CREATE);
    setIsModalOpen(false);
    setSelectedTimeAwayType(null);
  };

  const renderTimeAwayTypes = (): ReactNode | null => {
    if (!timeAwayTypes.length) return null;

    return (
      <>
        {timeAwayTypes.map(({ id, name }) => (
          <Fragment key={id}>
            <SectionItem
              itemContent={name}
              onDelete={() => handleOpenDeleteModal(id ?? 0)}
              onEdit={() => handleOpenOnEdit(id ?? 0)}
              onCancel={() => setSelectedTimeAwayType(null)}
              onSave={handleSaveEdited}
              openEdit={selectedTimeAwayType?.id === id && actionType === Action.UPDATE}
            />
            {selectedTimeAwayType?.id === id && actionType === Action.UPDATE && (
              <TimeAwayTypeForm
                formData={formData}
                setFormData={setFormData}
                colors={allColors}
                selectedColors={disabledColors}
                displayMode="settings-section"
              />
            )}
          </Fragment>
        ))}
      </>
    );
  };

  return (
    <Section
      title="Time away types"
      iconType="add"
      type={Action.UPDATE === actionType ? 'edit' : 'overview'}
      onClick={handleModalOpen}
    >
      <div className={classes['c-time-away-type']}>
        {renderTimeAwayTypes()}
        {actionType === Action.DELETE ? (
          <ActionConfirmationModal
            message={`you want to delete time away type "${
              timeAwayTypes.find((timeAwayType) => timeAwayType.id === selectedTimeAwayType?.id)
                ?.name ?? ''
            }"?`}
            isModalOpen={isModalOpen}
            isLoading={isLoading}
            handleNo={handleModalClose}
            handleYes={handleOnDelete}
            closeModal={handleModalClose}
          />
        ) : (
          <PopUpModal
            title="Create new time away type"
            opened={isModalOpen}
            width={30}
            closeModal={handleModalClose}
          >
            <TimeAwayTypeForm
              formData={formData}
              colors={allColors}
              setFormData={setFormData}
              onSave={handleCreateNew}
              selectedColors={disabledColors}
              displayMode="modal"
            />
          </PopUpModal>
        )}
      </div>
    </Section>
  );
};

export default TimeAwayType;
