import {
  type ChangeEvent,
  type KeyboardEvent,
  useState,
  type InputHTMLAttributes,
  useCallback,
  useMemo,
} from 'react';
import classes from './Input.module.scss';
import { ReactComponent as WarningSvg } from 'assets/orange-svgs/Warning-orange.svg';
import { Calendar } from 'react-date-range';
import { useOnClickOutside } from 'hooks';
import { pascalCase, replaceUnderscoreWithBlankSpaceInString } from 'utils/utilFunctions';
import { formatDateForInput } from 'utils/dates';
import { ReactComponent as CalendarIcon } from 'assets/CalendarIcon.svg';
import { IconButton } from '../IconButton';
import { ErrorMessage } from '../ErrorMessage';

type Props = {
  label?: string;
  name?: string;
  id: string;
  placeholder?: string;
  type?: InputHTMLAttributes<HTMLInputElement>['type'];
  value?: string;
  defaultValue?: string;
  size?: 'small' | 'medium' | 'large' | 'xl';
  icon?: 'required' | JSX.Element;
  inputValuePosition?: 'left' | 'right';
  readOnly?: boolean;
  handleIconClick?: () => void;
  setValue?: (value: string) => void;
  handleOnChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleOnKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  handleOnBlur?: () => void;
  errorMessage?: string;
  autoFocus?: boolean;
  min?: Date;
  max?: Date;
  pascalCaseState?: boolean;
  isSubmitted?: boolean;
  isInvalid?: boolean;
  required?: boolean;
  isOptional?: boolean;
  datePickerPosition?: 'left' | 'right' | 'center';
  borderColor?: 'gray' | 'error';
};

export const Input = ({
  label,
  name,
  id,
  placeholder,
  type = 'text',
  value,
  defaultValue,
  size,
  icon,
  inputValuePosition,
  readOnly,
  handleIconClick,
  setValue,
  handleOnChange,
  handleOnKeyDown,
  handleOnBlur,
  errorMessage,
  autoFocus,
  min,
  max,
  pascalCaseState,
  isSubmitted = false,
  isOptional = false,
  isInvalid,
  required,
  datePickerPosition = 'left',
  borderColor,
}: Props) => {
  const [requiredAltShow, setRequiredAltShow] = useState<boolean>(false);
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);

  const shouldRenderCalendarIcon = useMemo(
    () => type === 'date' && !value && !openCalendar && !readOnly,
    [openCalendar, readOnly, type, value],
  );

  const divRef = useOnClickOutside(() => {
    setOpenCalendar(false);
  });

  const isFieldInvalid = isSubmitted && (!value || value.trim() === '' || isInvalid);

  const handleOnChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (!setValue) {
      handleOnChange?.(event);
      return;
    }

    if (type === 'date' && value === '') {
      setValue(formatDateForInput(new Date()));
      return;
    }

    setValue(value);
  };

  const handleOnDateChange = useCallback(
    (date: Date) => {
      setValue?.(date.toString());
      setOpenCalendar(false);
    },
    [setValue],
  );

  const renderDatePicker = useCallback(() => {
    if (!openCalendar) return;

    return (
      <div
        className={`${classes['c-input__calendar']} ${
          classes[`c-input__calendar--${datePickerPosition}`]
        }`}
      >
        <Calendar
          onChange={handleOnDateChange}
          date={value ? new Date(value) : new Date()}
          minDate={min && new Date(min)}
          maxDate={max && new Date(max)}
        />
      </div>
    );
  }, [datePickerPosition, handleOnDateChange, max, min, openCalendar, value]);

  return (
    <div className={classes['c-input']} ref={divRef}>
      {label && (
        <label
          className={`${classes['c-input__input-label']} ${
            required ? classes['c-input__input-label--required'] : ''
          }`}
          htmlFor={id}
        >
          {label}

          {isOptional && <span className={classes['c-input__optional-text']}> (optional)</span>}
        </label>
      )}
      <div className={classes['c-input__field-holder']}>
        <input
          className={`${classes['c-input__field']} ${classes[`c-input__field--${size}`]}  ${
            readOnly && classes['c-input__field--read-only']
          } ${type === 'date' ? classes['c-input__field--calendar'] : ''} ${
            openCalendar ? classes['c-input__field--calendar-opened'] : ''
          } ${icon && `${classes['c-input__field--medium-width']}`} ${
            inputValuePosition === 'right' ? classes['c-input__field--align-right'] : ''
          } ${isFieldInvalid ? classes['c-input__field--invalid'] : ''} ${
            borderColor ? classes[`c-input__field--${borderColor}-border`] : ''
          }`}
          id={id}
          name={name}
          type={type}
          placeholder={!readOnly ? placeholder : undefined}
          value={
            pascalCaseState ? pascalCase(replaceUnderscoreWithBlankSpaceInString(value)) : value
          }
          defaultValue={defaultValue}
          readOnly={readOnly}
          onChange={handleOnChangeInput}
          onBlur={handleOnBlur}
          autoFocus={autoFocus && !readOnly}
          onFocus={() => setOpenCalendar(true)}
          onKeyDown={handleOnKeyDown}
          min={formatDateForInput(min ?? '')}
        />
        {icon ? (
          <div className={classes['c-input__icon']}>
            {icon === 'required' ? (
              <div className={classes['c-input__required-icon']}>
                <WarningSvg
                  onMouseEnter={() => setRequiredAltShow(true)}
                  onMouseLeave={() => setRequiredAltShow(false)}
                />
                {requiredAltShow && (
                  <div className={classes['c-input__required-text']}>REQUIRED FIELD</div>
                )}
              </div>
            ) : (
              <div onClick={handleIconClick}>{icon}</div>
            )}
          </div>
        ) : null}
        {shouldRenderCalendarIcon && (
          <IconButton
            icon={<CalendarIcon className={classes['c-input__calendar-icon']} />}
            aria-label="Open calendar"
            onClick={() => setOpenCalendar(true)}
          />
        )}
      </div>
      {type === 'date' && renderDatePicker()}

      {!readOnly && errorMessage && <ErrorMessage isInputInvalid invalidMessage={errorMessage} />}
    </div>
  );
};
