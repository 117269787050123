import { ChangeEvent, Dispatch, SetStateAction, useCallback } from 'react';
import classes from './TimeAwayTypeForm.module.scss';
import type { TimeAwayTypeCategory } from 'modules/shared/model';
import ColorSelector from '../ColorSelector/ColorSelector';
import { SectionInfoEdit } from 'modules/shared/components';
import { Button, InputRadio } from 'components/core';

export type TimeAwayTypeFormData = {
  name: string;
  category: TimeAwayTypeCategory;
  color: string;
};

type Props = {
  formData: TimeAwayTypeFormData;
  colors: string[];
  selectedColors: string[];
  displayMode?: 'settings-section' | 'modal';
  setFormData: Dispatch<SetStateAction<TimeAwayTypeFormData>>;
  onSave?: () => void;
};

const TimeAwayTypeForm = ({
  formData,
  colors,
  selectedColors,
  displayMode,
  setFormData,
  onSave,
}: Props) => {
  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFormData((prev) => ({
        ...prev,
        [event.target.name]: event.target.value,
      }));
    },
    [setFormData],
  );

  const handleSelectColor = useCallback(
    (color: string) => {
      setFormData((prev) => ({ ...prev, color }));
    },
    [setFormData],
  );

  const renderColorSelectors = useCallback(
    () =>
      colors.map((color) => (
        <ColorSelector
          key={color}
          color={color}
          isSelected={formData.color === color}
          isDisabled={selectedColors.includes(color)}
          onSelectColor={() => handleSelectColor(color)}
        />
      )),
    [colors, formData.color, handleSelectColor, selectedColors],
  );

  return (
    <div
      className={`${classes['c-time-away-type-form']} ${
        displayMode === 'modal' ? classes['c-time-away-type-form--modal'] : ''
      }`}
    >
      <div className={classes['c-time-away-type-form__form-inputs-wrap']}>
        <SectionInfoEdit
          name="name"
          data={formData.name}
          handleChange={handleInputChange}
          displayMode={displayMode}
        />
        <div className={classes['c-time-away-type-form__category-wrap']}>
          <h4 className={classes['c-time-away-type-form__heading']}>Category</h4>
          <div className={classes['c-time-away-type-form__radio-wrap']}>
            <InputRadio
              id="timeOff"
              value="TIME_OFF"
              name="category"
              label="Time off"
              checked={formData.category === 'TIME_OFF'}
              onChange={handleInputChange}
              size={displayMode === 'modal' ? 'medium' : 'small'}
            />
          </div>
          <div className={classes['c-time-away-type-form__radio-wrap']}>
            <InputRadio
              id="workingAway"
              value="WORKING_AWAY"
              name="category"
              label="Working away"
              checked={formData.category === 'WORKING_AWAY'}
              onChange={handleInputChange}
              size={displayMode === 'modal' ? 'medium' : 'small'}
            />
          </div>
        </div>
      </div>
      <div className={classes['c-time-away-type-form__color-wrap']}>
        <h4
          className={`${classes['c-time-away-type-form__heading']} ${classes['c-time-away-type-form__heading--color']}`}
        >
          Color
        </h4>
        <div className={classes['c-time-away-type-form__colors-wrap']}>
          {renderColorSelectors()}
        </div>
      </div>

      {!!onSave && (
        <Button size="medium" onClick={onSave}>
          Save
        </Button>
      )}
    </div>
  );
};

export default TimeAwayTypeForm;
