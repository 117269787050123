import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { removeOrganization, setOrganization } from 'state/shared/organization';
import { removeUserData, setUserData } from 'modules/auth/redux/authSlice';
import { clearLocalStorageTokens, getAccessToken, getTokenPayload } from 'utils/auth/tokenService';
import { useAppDispatch } from 'state/redux-hooks/reduxHooks';
import { initializeState } from 'components/layout/Toolbar/redux/toolbarSlice';

const unprotectedRoutes = [
  '/sign-up',
  '/reset-password',
  '/register/first-time-login',
  '/oauth2/redirect/microsoft',
  '/oauth2/redirect/google',
];

export const useAuth = () => {
  const [isLoad, setIsLoad] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const handleUnauthorized = useCallback(() => {
    dispatch(removeUserData());
    dispatch(initializeState());
    dispatch(removeOrganization());
    clearLocalStorageTokens();

    if (!unprotectedRoutes.includes(pathname) && pathname !== '/') {
      navigate('/people');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate]);

  const initializeAuth = useCallback(() => {
    try {
      const accessToken = getAccessToken();
      const tokenPayload = getTokenPayload();

      if (!tokenPayload || !accessToken) {
        handleUnauthorized();
        return;
      }

      if (pathname === '/') {
        navigate('/people');
      }

      dispatch(setUserData({ tokenPayload, accessToken }));
      dispatch(setOrganization(tokenPayload.organization));
    } catch {
      handleUnauthorized();
    } finally {
      setIsLoad(true);
    }
  }, [dispatch, handleUnauthorized]);

  useEffect(() => {
    initializeAuth();
  }, [initializeAuth]);

  return { isLoad };
};
