import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as ZeroLogo } from 'assets/zero-logo.svg';
import logoPlaceholder from 'assets/Building.svg';
import { SidebarItem } from './SidebarItem';
import classes from './Sidebar.module.scss';
import { useUserRole, useWindowSize } from 'hooks';
import { navigationItems } from 'utils/NavigationItems';
import { getOrganization } from 'modules/settings/redux/settingsActions';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import logoSmall from 'assets/zero-logo-black-small.png';
import SidebarActions from './SidebarActions/SidebarActions';

const userRestrictedPaths = ['Workflows', 'Projects', 'Stats', 'Settings', 'Time away requests'];

const adminExcludedPaths = ['My tasks'];

const BREAKPOINT = 1280;

export const Sidebar = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean>(false);

  const { user } = useAppSelector((state) => state.auth);
  const { company } = useAppSelector((state) => state.settings);
  const organizationId = useAppSelector((state) => state.organization.organization)?.id;

  const { width } = useWindowSize();
  const { isRegularUser } = useUserRole(user?.role);

  const sidebarItems = isRegularUser
    ? navigationItems.filter((item) => !userRestrictedPaths.includes(item.label))
    : navigationItems.filter((item) => !adminExcludedPaths.includes(item.label));

  const dispatch = useAppDispatch();

  const getCompanyData = useCallback(() => {
    if (!organizationId) return;

    dispatch(getOrganization(organizationId));
  }, [dispatch, organizationId]);

  useEffect(() => {
    getCompanyData();
  }, [getCompanyData]);

  useEffect(() => {
    width < BREAKPOINT ? setIsSidebarCollapsed(true) : setIsSidebarCollapsed(false);
  }, [width]);

  return (
    <div
      className={`${classes['c-sidebar']} ${
        isSidebarCollapsed ? classes['c-sidebar--collapsed'] : ''
      }`}
    >
      <div className={classes['c-sidebar__upper-nav']}>
        <div className={classes['c-sidebar__header']}>
          {company && (
            <>
              <Link to="/people" aria-label="Go to home page">
                <img
                  className={classes['c-sidebar__header-image']}
                  src={company.logoPath ?? logoPlaceholder}
                  alt="Company logo"
                />
              </Link>
              {!isSidebarCollapsed && (
                <h1 className={classes['c-sidebar__header-title']}>{company.name}</h1>
              )}
            </>
          )}
        </div>
        <div className={classes['c-sidebar__actions']}>
          <SidebarActions isSidebarCollapsed={isSidebarCollapsed} />
        </div>
        <div className={classes['c-sidebar__items']}>
          <ul className={classes['c-sidebar__nav']}>
            {sidebarItems.map(({ path, label, icon, submenu }) => (
              <SidebarItem
                key={label}
                path={path}
                label={label}
                icon={icon}
                submenu={submenu}
                isSidebarCollapsed={isSidebarCollapsed}
              />
            ))}
          </ul>
        </div>
      </div>
      <div className={classes['c-sidebar__lower-nav']}>
        {isSidebarCollapsed ? (
          <img className={classes['c-sidebar__lower-nav__logo-image']} src={logoSmall} alt="logo" />
        ) : (
          <ZeroLogo className={classes['c-sidebar__lower-nav__logo-image--large']} />
        )}
      </div>
    </div>
  );
};
