export const colors = [
  '#ad1357',
  '#f4511e',
  '#e74c3c',
  '#f6b93b',
  '#2ecc71',
  '#16a085',
  '#3498db',
  '#9b59b6',
  '#34495e',
  '#95a5a6',
];
