import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classes from './People.module.scss';
import { UserInfo } from 'modules/shared/components';
import {
  Filter,
  Pagination,
  PopUpModal,
  AppliedFilters,
  Button,
  Status,
  PeoplePopup,
} from 'components/core';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { getUsers } from 'modules/people/redux/usersActions';
import UserItem from '../UserItem/UserItem';
import { RequestState, UserType } from 'config/constants';
import UserTypeFilter from '../UserTypeFilter/UserTypeFilter';
import { ReactComponent as AddPeople } from 'assets/AddPeople.svg';
import { useToolbarTitle, useUserRole } from 'hooks';
import { selectPaginationData, selectUsers } from 'modules/people/redux/peopleSlice';
import { useUrlFilters } from 'hooks/useUrlFilters';
import { selectToolbar } from 'components/layout/Toolbar/redux/toolbarSlice';
import { selectFilters } from 'components/core/Filter/redux/filterSlice';
import { getSortProperty } from 'components/core/Filter/utils';
import { useLocation } from 'react-router-dom';
import { selectAuthUser } from 'modules/auth/redux/authSlice';
import FiltersButton from 'components/core/FiltersButton/FiltersButton';
import { getToolbarSubtitle } from 'components/layout/Toolbar/utils';
import { ActiveMember, NewMember } from 'modules/people/model/User';

const defaultNewUser = {
  firstName: '',
  lastName: '',
  personalEmail: '',
  startDate: new Date(),
  jobTitle: '',
};

const defaultActiveMember = {
  firstName: '',
  lastName: '',
  email: '',
  jobTitle: '',
  department: '',
  reportTo: '',
  startDate: new Date(),
  firstWorkingDay: new Date(),
  location: '',
};
const People = () => {
  const paginationData = useAppSelector(selectPaginationData);
  const users = useAppSelector(selectUsers);
  const { user } = useAppSelector(selectAuthUser);
  const [newUser, setNewUser] = useState<NewMember>(defaultNewUser);
  const [activeMember, setActiveMember] = useState<ActiveMember>(defaultActiveMember);
  const { isAdminOrSuperAdmin } = useUserRole(user?.role);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const initialUrlPage = params.get('page');
  const userTypeParam = params.get('userType');

  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(initialUrlPage ? +initialUrlPage : 0);
  const [isAddPeopleModalOpen, setIsAddPeopleModalOpen] = useState<boolean>(false);
  const [isUserInfoOpen, setIsUserInfoOpen] = useState(false);
  const [selectedType, setSelectedType] = useState<UserType>(UserType.ACTIVE_MEMBER);

  const { appliedFilters } = useAppSelector(selectFilters);
  const { filterClicked, inputFieldValue } = useAppSelector(selectToolbar);
  const loading = useAppSelector((state) => state.users.loading);
  const isHighlightedRef = useRef(false);

  const dispatch = useAppDispatch();

  const subtitle = useMemo(() => {
    if (!paginationData) return;

    return getToolbarSubtitle(appliedFilters, paginationData, 'members');
  }, [appliedFilters, paginationData]);

  const getAllUsers = (search: string) => {
    if (search.length) {
      dispatch(getUsers(search));
    }
  };

  useUrlFilters({
    page: currentPage,
    sort: getSortProperty(appliedFilters.sortBy.filterValue),
    userType: selectedType,
    setCurrentPage,
    setUserType: setSelectedType,
    fetchData: getAllUsers,
  });

  useToolbarTitle({ title: ['People'], subtitle }, [subtitle]);

  const handleSelectedUser = useCallback((id: number) => {
    setSelectedUserId(id);
    setIsUserInfoOpen(true);
  }, []);

  const switchPopup = (value: boolean) => {
    setIsAddPeopleModalOpen(value);
  };

  const closePopup = () => {
    switchPopup(false);
    setActiveMember(defaultActiveMember);
  };

  const closeModalOnClickOutside = () => {
    switchPopup(false);
  };

  const areFiltersApplied = useMemo(
    () =>
      Object.values(appliedFilters).some((filterCategory) => {
        if (Array.isArray(filterCategory)) return filterCategory.length;

        return filterCategory.id !== -1;
      }),
    [appliedFilters],
  );

  const renderPageContentHeader = () => {
    if (filterClicked) {
      return <Filter />;
    }

    if (areFiltersApplied) {
      return <AppliedFilters />;
    }
  };

  const renderTableHeader = useCallback(() => {
    return (
      <div className={classes['c-people-overview__table-header']}>
        {isAdminOrSuperAdmin && (
          <UserTypeFilter selectedType={selectedType} setSelectedType={setSelectedType} />
        )}
        <div className={classes['c-people-overview__table-header--right']}>
          <FiltersButton />
          <div className={classes['c-people-overview__pagination-container']}>
            <Pagination
              itemsPerPage={20}
              first={paginationData?.first}
              last={paginationData?.last}
              totalFilteredElements={paginationData?.totalFilteredElements}
              numberOfElements={paginationData?.numberOfElements}
              currentPage={currentPage}
              totalPages={paginationData?.totalPages ?? 1}
              setCurrentPage={setCurrentPage}
            />
          </div>
          {isAdminOrSuperAdmin && (
            <Button leftIcon={<AddPeople />} onClick={() => switchPopup(true)}>
              Add member
            </Button>
          )}
        </div>
      </div>
    );
  }, [currentPage, isAdminOrSuperAdmin, paginationData, selectedType]);

  const renderTableContent = useCallback(() => {
    if (
      !users.length &&
      (areFiltersApplied || inputFieldValue || selectedType === UserType.NEW_HIRE)
    )
      return (
        <span className={classes['c-people-overview__data--missing']}>
          There are no results for the desired {inputFieldValue ? 'search ' : 'filters '}
          criteria.
        </span>
      );

    if (inputFieldValue && inputFieldValue.length >= 3) {
      isHighlightedRef.current = true;
    } else {
      isHighlightedRef.current = false;
    }
    return users.map((user) => (
      <UserItem
        user={user}
        isSelected={user.id === selectedUserId}
        selectedType={selectedType}
        searchValue={inputFieldValue}
        handleSelectedUser={handleSelectedUser}
        key={user.id}
        isHighligted={isHighlightedRef.current}
      />
    ));
  }, [
    areFiltersApplied,
    handleSelectedUser,
    selectedType,
    selectedUserId,
    users,
    isHighlightedRef,
  ]);

  const renderUsersTable = useCallback(() => {
    return (
      <div className={classes['c-people-overview__table']}>
        {renderTableHeader()}
        <Status isLoading={loading === RequestState.PENDING}>
          <div className={classes['c-people-overview__data']}>{renderTableContent()}</div>
        </Status>
      </div>
    );
  }, [loading, renderTableContent, renderTableHeader]);

  useEffect(() => {
    if (!userTypeParam) {
      setSelectedType(UserType.ALL_USERS);
      return;
    }

    setSelectedType(userTypeParam as UserType);
  }, [userTypeParam]);

  return (
    <div className={classes['c-people-overview']}>
      <div className={classes['c-people-overview__container']}>
        {renderPageContentHeader()}
        {renderUsersTable()}
      </div>
      <UserInfo
        id={selectedUserId}
        setSelectedId={setSelectedUserId}
        isOpen={isUserInfoOpen}
        setIsOpen={setIsUserInfoOpen}
      />
      <PopUpModal
        title="Add member"
        opened={isAddPeopleModalOpen}
        closeModal={closePopup}
        width={30}
        top="10%"
        closeModalOnClickOutside={closeModalOnClickOutside}
      >
        <PeoplePopup
          setActiveMember={setActiveMember}
          setNewUser={setNewUser}
          activeMember={activeMember}
          newUser={newUser}
          selectedUserType={selectedType}
          setIsPopupOpen={switchPopup}
        />
      </PopUpModal>
    </div>
  );
};

export default People;
